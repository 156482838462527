// components/Analytics.js

const Analytics = ({ children }) => (
    <>
        {/* Your head content here */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X2J8V6QYG8" />
        <script
            dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'G-X2J8V6QYG8');
          `,
            }}
        />

        {children}

    </>
);

export default Analytics;
