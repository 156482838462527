import React from 'react'

const Testimonial = () => {
    return (
        <div>

            <div className="container-fluid py-5" id="testimonial">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1
                            className="display-1 text-uppercase text-white"
                            style={{ WebkitTextStroke: "1px #dee2e6" }}
                        >
                            Review
                        </h1>
                        <h1 className="position-absolute text-uppercase text-primary">
                            Other Say
                        </h1>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="testimonial-carousel">

                                {/* <div className="owl-carousel testimonial-carousel"> */}
                                <div className="text-center">
                                    <i className="fa fa-3x fa-quote-left text-primary mb-4" />
                                    <h4 className="font-weight-light mb-4">
                                        Shrish, is a dedicated scientist, who plans his experiments systematically and performs them with great passion and rigor. Moreover, his interpretation skills are also outstanding. He is diligent, motivated and possess research acumen, which is based on his curious scientific temperament. I am glad to write that he possesses extensive knowledge and has acquired skills which are cross disciplinary. Furthermore, he has been punctual in the responsibilities assigned to him. He has great communication skills, and is certainly a team player.
                                        Further, on the personal level, he is humble, truthful, and a compassionate individual.
                                        I wish him all the best for his future endeavors.
                                    </h4>
                                    <a href="https://www.linkedin.com/in/sarmadhanif?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3B2geuZNAQRKyy1Q5feLWhzw%3D%3D" target="_blank" rel="noreferrer" >
                                        <img
                                            className="img-fluid rounded-circle mx-auto mb-3"
                                            src="imgs/dr.sarmad.jpg"
                                            style={{ width: 80, height: 80 }}
                                        />
                                        <h5 className="font-weight-bold m-0">Sarmad Hanif</h5>
                                        <span>Sr Research Scientist</span>
                                    </a>
                                </div>
                                {/* <div className="text-center">
                                    <i className="fa fa-3x fa-quote-left text-primary mb-4" />
                                    <h4 className="font-weight-light mb-4">
                                        Dolor eirmod diam stet kasd sed. Aliqu rebum est eos. Rebum
                                        elitr dolore et eos labore, stet justo sed est sed. Diam sed sed
                                        dolor stet accusam amet eirmod eos, labore diam clita
                                    </h4>
                                    <img
                                        className="img-fluid rounded-circle mx-auto mb-3"
                                        src="img/testimonial-2.jpg"
                                        style={{ width: 80, height: 80 }}
                                    />
                                    <h5 className="font-weight-bold m-0">Client Name</h5>
                                    <span>Profession</span>
                                </div>
                                <div className="text-center">
                                    <i className="fa fa-3x fa-quote-left text-primary mb-4" />
                                    <h4 className="font-weight-light mb-4">
                                        Dolor eirmod diam stet kasd sed. Aliqu rebum est eos. Rebum
                                        elitr dolore et eos labore, stet justo sed est sed. Diam sed sed
                                        dolor stet accusam amet eirmod eos, labore diam clita
                                    </h4>
                                    <img
                                        className="img-fluid rounded-circle mx-auto mb-3"
                                        src="img/testimonial-3.jpg"
                                        style={{ width: 80, height: 80 }}
                                    />
                                    <h5 className="font-weight-bold m-0">Client Name</h5>
                                    <span>Profession</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial