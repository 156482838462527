import React from 'react'

const Qualification = () => {
    return (
        <div>
            <div className="container-fluid py-5" id="qualification">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1
                            className="display-1 text-uppercase text-white"
                            style={{ WebkitTextStroke: "1px #dee2e6" }}
                        >
                            Quality
                        </h1>
                        <h1 className="position-absolute text-uppercase text-primary">
                            Education &amp; Expericence
                        </h1>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h3 className="mb-4">My Education</h3>
                            <div className="border-left border-primary pt-2 pl-4 ml-2">
                                <div className="position-relative mb-4">
                                    <i
                                        className="far fa-dot-circle text-primary position-absolute"
                                        style={{ top: 2, left: "-32px" }}
                                    />
                                    <h5 className="font-weight-bold mb-1">Master's Degree (M. Tech), Biotechnology</h5>
                                    <p className="mb-2">
                                        <strong>Institute of Engineering and Technology</strong> |{" "}
                                        <small>Jul 2017 - Oct 2019</small>
                                    </p>
                                    <p>

                                    </p>
                                </div>
                                <div className="position-relative mb-4">
                                    <i
                                        className="far fa-dot-circle text-primary position-absolute"
                                        style={{ top: 2, left: "-32px" }}
                                    />
                                    <h5 className="font-weight-bold mb-1">
                                        Bachelor’s Degree (B. Tech), Bioengineering
                                    </h5>
                                    <p className="mb-2">
                                        <strong>
                                            Integral University, Lucknow,Uttar Pradesh</strong> |{" "}
                                        <small>2011 - 2015</small>
                                    </p>
                                    <p>
                                        {/* STUDENT COORDINATOR OF TRAINING & PLACEMENT CELL
                                        PARTICIPATED IN MOST OF THE CONFERENCES & SEMINARS */}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="mb-4">My Expericence</h3>
                            <div className="border-left border-primary pt-2 pl-4 ml-2">
                                <div className="position-relative mb-4">
                                    <i
                                        className="far fa-dot-circle text-primary position-absolute"
                                        style={{ top: 2, left: "-32px" }}
                                    />
                                    <h5 className="font-weight-bold mb-1">Invigorate Biotechnologies LLP, Mohali, Punjab</h5>
                                    <p className="mb-2">
                                        <strong>Research Scientist </strong> | <small>February 2023 - Till Date</small>
                                    </p>
                                    <p>
                                        {/* Tempor eos dolore amet tempor dolor tempor. Dolore ea magna sit
                                        amet dolor eirmod. Eos ipsum est tempor dolor. Clita lorem kasd
                                        sed ea lorem diam ea lorem eirmod duo sit ipsum stet lorem diam */}
                                    </p>
                                </div>
                                <div className="position-relative mb-4">
                                    <i
                                        className="far fa-dot-circle text-primary position-absolute"
                                        style={{ top: 2, left: "-32px" }}
                                    />
                                    <h5 className="font-weight-bold mb-1">BioBridge Healthcare Pvt. Limited, Chandigarh</h5>
                                    <p className="mb-2">
                                        <strong> Senior Executive Officer (R&D-QC)  </strong> | <small>July 2021 - Feb 2023</small>
                                    </p>
                                    <p>
                                        {/* Tempor eos dolore amet tempor dolor tempor. Dolore ea magna sit
                                        amet dolor eirmod. Eos ipsum est tempor dolor. Clita lorem kasd
                                        sed ea lorem diam ea lorem eirmod duo sit ipsum stet lorem diam */}
                                    </p>
                                </div>
                                <div className="position-relative mb-4">
                                    <i
                                        className="far fa-dot-circle text-primary position-absolute"
                                        style={{ top: 2, left: "-32px" }}
                                    />
                                    <h5 className="font-weight-bold mb-1">BioGLOBE Research & Solutions LLP, Delhi</h5>
                                    <p className="mb-2">
                                        <strong>Technical Officer</strong> | <small>2019 - 2021</small>
                                    </p>
                                    <p>



                                    </p>
                                </div>
                                <div className="position-relative mb-4">
                                    <i
                                        className="far fa-dot-circle text-primary position-absolute"
                                        style={{ top: 2, left: "-32px" }}
                                    />
                                    <h5 className="font-weight-bold mb-1">Venus Remedies Limited, Baddi (H.P.) & Panchkula (Haryana)</h5>
                                    <p className="mb-2">
                                        <strong>Assistant Manager (QC)</strong> | <small>2015 - 2017</small>
                                    </p>
                                    <p>
                                        {/* Assistant Manager (QC) (Apr. 2016 to Apr. 2017) Unit- I Baddi (H.P.)
                                        Executive Microbiologist (Jan. 2016 to Mar. 2016) Unit-II Baddi (H.P.)
                                        Management Trainee (QC) (Mar.2015 to Dec. 2015) Unit-II Baddi (H.P.) */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Qualification