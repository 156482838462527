import React from 'react'
import Navbar from './Components/Navbar'
import Banner from './Components/Banner'
import About from './Components/About'
import Qualification from './Components/Qualification'
import Skill from './Components/Skill'
import Services from './Components/Services'
import Portfolio from './Components/Portfolio'
import Testimonial from './Components/Testimonial'
import VideoComp from './Components/VideoComp'
import Certification from './Components/Certification'


function Hero() {
    return (
        <>
            <>
                {/* Navbar Start */}
                <Navbar />
                {/* Navbar End */}
                {/* Video Modal Start */}
                <VideoComp />
                {/* Video Modal End */}
                {/* Header Start */}
                <Banner />
                {/* Header End */}
                {/* About Start */}
                <About />
                {/* About End */}
                {/* Qualification Start */}
                <Qualification />
                {/* Qualification End */}
                {/* Skill Start */}
                {/* <Skill /> */}
                {/* Skill End */}
                {/* Services Start */}
                <Services />
                {/* Services End */}

                <Certification />

                {/* Testimonial Start */}
                <Testimonial />
                {/* Testimonial End */}
                {/* Blog Start */}
                {/* Blog End */}
                <Portfolio />
                {/* Contact Start */}
                <div className="container-fluid py-5" id="contact">
                    <div className="container">
                        <div className="position-relative d-flex align-items-center justify-content-center">
                            <h1
                                className="display-1 text-uppercase text-white"
                                style={{ WebkitTextStroke: "1px #dee2e6" }}
                            >
                                Contact
                            </h1>
                            <h1 className="position-absolute text-uppercase text-primary">
                                Contact Me
                            </h1>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="contact-form text-center">
                                    <div id="success" />
                                    <form name="sentMessage" id="contactForm" noValidate="novalidate">
                                        <div className="form-row">
                                            <div className="control-group col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control p-4"
                                                    id="name"
                                                    placeholder="Your Name"
                                                    required="required"
                                                    data-validation-required-message="Please enter your name"
                                                />
                                                <p className="help-block text-danger" />
                                            </div>
                                            <div className="control-group col-sm-6">
                                                <input
                                                    type="email"
                                                    className="form-control p-4"
                                                    id="email"
                                                    placeholder="Your Email"
                                                    required="required"
                                                    data-validation-required-message="Please enter your email"
                                                />
                                                <p className="help-block text-danger" />
                                            </div>
                                        </div>
                                        <div className="control-group">
                                            <input
                                                type="text"
                                                className="form-control p-4"
                                                id="subject"
                                                placeholder="Subject"
                                                required="required"
                                                data-validation-required-message="Please enter a subject"
                                            />
                                            <p className="help-block text-danger" />
                                        </div>
                                        <div className="control-group">
                                            <textarea
                                                className="form-control py-3 px-4"
                                                rows={5}
                                                id="message"
                                                placeholder="Message"
                                                required="required"
                                                data-validation-required-message="Please enter your message"
                                                defaultValue={""}
                                            />
                                            <p className="help-block text-danger" />
                                        </div>
                                        <div>
                                            <button
                                                className="btn btn-outline-primary"
                                                type="submit"
                                                id="sendMessageButton"
                                            >
                                                Send Message
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Contact End */}
                {/* Footer Start */}
                <div className="container-fluid bg-primary text-white mt-5 py-5 px-sm-3 px-md-5">
                    <div className="container text-center py-5">
                        <div className="d-flex justify-content-center mb-4">
                            <a className="btn btn-light btn-social mr-2" href="">
                                <i className="fab fa-twitter" />
                            </a>
                            <a className="btn btn-light btn-social mr-2" href="">
                                <i className="fab fa-facebook-f" />
                            </a>
                            <a className="btn btn-light btn-social mr-2" target='_blank' href="https://www.linkedin.com/in/shrish-chandra-pandey/">
                                <i className="fab fa-linkedin-in" />
                            </a>
                            <a className="btn btn-light btn-social" target='_blank' href="https://www.instagram.com/shrish_iet?igsh=NTc4MTIwNjQ2YQ%3D%3D">
                                <i className="fab fa-instagram" />
                            </a>
                        </div>
                        <div className="d-flex justify-content-center mb-3">
                            <a className="text-white" href="">
                                Privacy
                            </a>
                            <span className="px-3">|</span>
                            <a className="text-white" href="">
                                Terms
                            </a>
                            <span className="px-3">|</span>
                            <a className="text-white" href="">
                                FAQs
                            </a>
                            <span className="px-3">|</span>
                            <a className="text-white" href="">
                                Help
                            </a>
                        </div>
                        <p className="m-0">
                            ©{" "}
                            <a className="text-white font-weight-bold" href="">
                                shrish.çv.com
                            </a>
                            . All Rights Reserved. Maintained by{" "}
                            <a className="text-white font-weight-bold" href="https://çv.com">
                                çv.com
                            </a>
                        </p>
                    </div>
                </div>
                {/* Footer End */}
                {/* Scroll to Bottom */}
                <i className="fa fa-2x fa-angle-down text-white scroll-to-bottom" />
                {/* Back to Top */}
                <a href="#" className="btn btn-outline-dark px-0 back-to-top">
                    <i className="fa fa-angle-double-up" />
                </a>

            </>


        </>
    )
}

export default Hero