import React from 'react'

const Portfolio = () => {
    return (
        <div>
            <div className="container-fluid pt-5 pb-3" id="portfolio">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1
                            className="display-1 text-uppercase text-white"
                            style={{ WebkitTextStroke: "1px #dee2e6" }}
                        >
                            Gallery
                        </h1>
                        <h1 className="position-absolute text-uppercase text-primary">
                            My Portfolio
                        </h1>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center mb-2">
                            <ul className="list-inline mb-4" id="portfolio-flters">
                                <li
                                    className="btn btn-sm btn-outline-primary m-1 active"
                                    data-filter="*"
                                >
                                    All
                                </li>
                                <li
                                    className="btn btn-sm btn-outline-primary m-1"
                                    data-filter=".first"
                                >
                                    First
                                </li>
                                <li
                                    className="btn btn-sm btn-outline-primary m-1"
                                    data-filter=".second"
                                >
                                    Second
                                </li>
                                <li
                                    className="btn btn-sm btn-outline-primary m-1"
                                    data-filter=".third"
                                >
                                    Third
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row portfolio-container">



                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item third">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish033.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish033.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item third">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish099.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish099.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item second">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish088.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish088.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div>




                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item second">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish022.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish022.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div>






                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item first">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish077.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish077.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item third">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish066.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish066.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div>



                        {/* <div className="col-lg-4 col-md-6 mb-4 portfolio-item first">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish044.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish044.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-6 mb-4 portfolio-item second">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish055.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish055.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6 mb-4 portfolio-item first">
                            <div className="position-relative overflow-hidden mb-2">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/shrish011.JPG"
                                    alt=""
                                />
                                <div className="portfolio-btn bg-primary d-flex align-items-center justify-content-center">
                                    <a href="imgs/shrish011.JPG" data-lightbox="portfolio">
                                        <i className="fa fa-plus text-white" style={{ fontSize: 60 }} />
                                    </a>
                                </div>
                            </div>
                        </div> */}






                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio