import React from 'react'

const Certification = () => {
    return (
        <>
            <div className="container-fluid pt-5" id="blog">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1
                            className="display-1 text-uppercase text-white"
                            style={{ WebkitTextStroke: "1px #dee2e6" }}
                        >
                            Certification
                        </h1>
                        <h1 className="position-absolute text-uppercase text-primary">
                            Certification
                        </h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 mb-5">
                            <div className="position-relative mb-4">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/Certification/BSC_BioNEST_Bio_Incubator.jpg"
                                    alt=""
                                />
                                {/* <div className="blog-date">
                                    <h4 className="font-weight-bold mb-n1">01</h4>
                                    <small className="text-white text-uppercase">Jan</small>
                                </div> */}
                            </div>
                            <h6 className="font-weight-medium mb-4 font-weight-bold">
                                Successfully Organized Two Days Training program on "The Basics of Bioprocessing: Upstream & Downstream" @BioNEST Bio Incubator Regional Centre for Biotechnology
                            </h6>
                            <a className="btn btn-sm btn-outline-primary py-2" href="">
                                Read More
                            </a>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="position-relative mb-4">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/Certification/thsti_ARHM_2023.jpg"
                                    alt=""
                                />
                                {/* <div className="blog-date">
                                    <h4 className="font-weight-bold mb-n1">01</h4>
                                    <small className="text-white text-uppercase">Jan</small>
                                </div> */}
                            </div>
                            <h6 className="font-weight-medium mb-4 font-weight-bold">
                                Two days Indo-US International Conference on Antimicrobial Resistance and Human Microbiome (ARHM 2023) @Translational Health Science Technology Institute, Faridabad
                            </h6>
                            <a className="btn btn-sm btn-outline-primary py-2" href="">
                                Read More
                            </a>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="position-relative mb-4">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/Certification/Biostatistics_Workshop.jpg"
                                    alt=""
                                />
                                {/* <div className="blog-date">
                                    <h4 className="font-weight-bold mb-n1">01</h4>
                                    <small className="text-white text-uppercase">Jan</small>
                                </div> */}
                            </div>
                            <h6 className="font-weight-medium mb-4 font-weight-bold">
                                Three Days Workshop on Statistics in Clinical Trials organized by CDSA & DBT @Translational Health Science Technology Institute, Faridabad
                            </h6>
                            <a className="btn btn-sm btn-outline-primary py-2" href="">
                                Read More
                            </a>
                        </div>

                        <div className="col-lg-4 mb-5">
                            <div className="position-relative mb-4">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/Certification/proteomics_BRICS.jpg"
                                    alt=""
                                />
                                {/* <div className="blog-date">
                                    <h4 className="font-weight-bold mb-n1">01</h4>
                                    <small className="text-white text-uppercase">Jan</small>
                                </div> */}
                            </div>
                            <h6 className="font-weight-medium mb-4 font-weight-bold">
                                One Day Symposium on Proteomics in Biotechnology and Biomedical Science Organized by BRICS @Translational Health Science Technology Institute, Faridabad
                            </h6>
                            <a className="btn btn-sm btn-outline-primary py-2" href="">
                                Read More
                            </a>
                        </div>


                        <div className="col-lg-4 mb-5">
                            <div className="position-relative mb-4">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/Certification/ARTSIP.jpg"
                                    alt=""
                                />
                                {/* <div className="blog-date">
                                    <h4 className="font-weight-bold mb-n1">01</h4>
                                    <small className="text-white text-uppercase">Jan</small>
                                </div> */}
                            </div>
                            <h6 className="font-weight-medium mb-4 font-weight-bold">
                                Successfully Completed One Week Online Faculty Development Programme on "Advanced Research Trends of Satellite Image Processing in Land Surface Monitoring" organized by Advanced Computing Research Lab HRDC @Integral University, Lucknow
                            </h6>
                            <a className="btn btn-sm btn-outline-primary py-2" href="">
                                Read More
                            </a>
                        </div>



                        <div className="col-lg-4 mb-5">
                            <div className="position-relative mb-4">
                                <img
                                    className="img-fluid rounded w-100"
                                    src="imgs/Certification/IMS_ghaziyabad.jpg"
                                    alt=""
                                />
                                {/* <div className="blog-date">
                                    <h4 className="font-weight-bold mb-n1">01</h4>
                                    <small className="text-white text-uppercase">Jan</small>
                                </div> */}
                            </div>
                            <h6 className="font-weight-medium mb-4 font-weight-bold">
                                2nd International Webinar Series on topic "Understanding Stress Response Adaptation in Adherent Invasive Escherichia coli" delivered by CIRB, College de France, Paris, France organized by IMS Ghaziabad
                            </h6>
                            <a className="btn btn-sm btn-outline-primary py-2" href="">
                                Read More
                            </a>
                        </div>






                    </div>
                </div>
            </div>

        </>
    )
}

export default Certification